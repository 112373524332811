html,
body {
  font-family: PingFang SC, Microsoft YaHei, sans-serif;
  font-size: 14px;
  line-height: 1;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
}
input {
  border: 0;
}
.card {
  position: relative;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 0 9px 2px rgba(0, 0, 0, 0.08);
  padding: 20px;
}
.card-title {
  padding: 0 10px;
  color: #202020;
  font-size: 18px;
  line-height: 24px;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  padding-left: 40px;
  font-weight: 600;
  margin-bottom: 20px;
}
.card-content {
  word-break: break-all;
  line-height: 1.5;
}
.card-content-item {
  margin: 15px 0px;
}
.card-content-item-label {
  margin-top: 15px;
  padding: 10px 0;
  font-size: 14px;
  color: #202020;
  font-weight: 600;
}
.checkout {
  border-radius: 11px;
  border: 1px solid #002b9e;
  font-size: 12px;
  font-weight: 400;
  color: #002b9e;
  line-height: 17px;
  margin-left: 25px;
  padding: 2px 8px;
}
.mask {
  margin-top: 40px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('../images/mask.jpg');
  position: relative;
}
.mask-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
}
.mask-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-color: #ffffff;
  z-index: 10000;
}
.mask-content-title {
  font-size: 18px;
  font-weight: 600;
  margin: 20px 0px;
}
.mask-content-hash {
  width: 600px;
  margin: 20px 0px;
}
.mask-content input {
  border-radius: 16px;
}
.block-address {
  display: flex;
}
.block-address-hash {
  margin-right: 10px;
  line-height: 20px;
}
.check-btn-text {
  border-radius: 50%;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: white !important;
  font-size: 13px;
  background-color: #002B9E;
  cursor: pointer;
  text-align: center;
}
.check-btn-content {
  font-weight: 400;
  color: #202020;
  line-height: 14px;
}
.check-btn-content-confirmed {
  font-weight: 600;
}
.check-btn-content-item {
  margin: 10px 0px;
}
.check-btn-content-extra-item {
  display: flex;
  margin: 10px 0px;
}
.check-btn-content-extra-item-btn {
  margin-left: 10px;
  display: inline-block;
  border-radius: 14px;
  border: 1px solid #002b9e;
  color: #002b9e;
  padding: 2px 4px;
  cursor: pointer;
}
.check-btn-content-extra-item-label {
  font-weight: 600;
}
.check-btn-content-extra-item-value {
  word-break: break-all;
}
.clearfix:after {
  content: "";
  display: block;
  clear: both;
}
.clearfix {
  *zoom: 1;
}
.text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.not-found {
  margin: 140px auto 100px;
  margin-top: 140px;
  width: 590px;
  height: 400px;
  background-image: url(../images/pic_404.png);
}
.chain .card {
  margin-bottom: 40px;
}
.chain-mask {
  margin-bottom: 40px;
  height: 350px;
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.chain-mask-title {
  font-size: 18px;
  font-weight: 600;
  margin: 20px 0px;
}
.chain-mask-hash {
  width: 700px;
  margin: 20px 0px;
}
.chain-trans-info {
  display: flex;
  margin-bottom: 40px;
}
.chain-trans-info-item {
  flex: 4;
}
.chain-trans-info-arrow {
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.chain-trans-info-arrow-image {
  width: 39px;
  height: 33px;
  background-image: url("../images/chain/arrow.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.chain-trans-info-arrow-text {
  margin-top: 18px;
  font-weight: bold;
  font-size: 16px;
  color: #002B9E;
}
.chain-trans-detail {
  margin-bottom: 40px;
}
.chain-origin-assets,
.chain-origin-table {
  margin-top: 40px;
}
.chain-assets-table {
  margin-bottom: 40px;
}
.chain-assets-modal .gallery-list {
  text-align: center;
}
.chain-settle .card {
  margin-bottom: 40px;
}
.header {
  position: relative;
  color: #ffffff;
  height: 333px;
  text-align: center;
  background-image: url("../images/home/header-background.jpg");
  background-size: 100%;
  background-position: center center;
  box-shadow: 1px 0 9px 2px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}
.header .title {
  padding-top: 20px;
  font-size: 30px;
}
.header .description {
  margin-top: 20px;
  font-size: 16px;
}
.header .statistics {
  max-width: 1200px;
  margin: 40px auto;
  display: flex;
  justify-content: space-around;
}
.header .statistics span {
  font-size: 30px;
  margin: 0 10px;
}
.header .statistics .des {
  margin-top: 15px;
}
.trade-box {
  text-align: center;
  margin-top: -40px;
  position: relative;
  z-index: 2;
}
.trade-box .trade-body {
  background-color: white;
  display: inline-block;
  padding: 16px;
  border-radius: 5px;
  box-shadow: 1px 0 9px 2px rgba(0, 0, 0, 0.08);
}
.trade-box .trade-body .box-title {
  text-align: left;
  color: #4A4A4A;
  font-size: 16px;
}
.trade-box .trade-body .content {
  margin-top: 10px;
  font-size: 36px;
  color: #666666;
  line-height: 45px;
}
.trade-box .trade-body .content .num {
  display: inline-block;
  background-color: #E7EBF6;
  height: 45px;
  width: 34px;
  margin: 0 4px;
  color: #002B9E;
  font-weight: 600;
}
.home .home-search {
  text-align: center;
  margin-top: 30px;
}
.home .home-block {
  margin-top: 25px;
}
.home .home-block .block-icon {
  background-image: url("../../styles/images/home/block.png");
}
.home .home-block .content {
  margin-top: 15px;
  display: flex;
  justify-content: space-around;
  text-align: center;
}
.home .home-block .content .num {
  color: #202020;
  font-size: 26px;
  font-weight: 600;
}
.home .home-block .content .block-des {
  margin-top: 20px;
  color: #4A4A4A;
  font-size: 16px;
}
.home .home-trade {
  margin-top: 25px;
}
.home .home-node {
  margin: 25px 0;
}
.trace .card {
  margin-bottom: 40px;
}
.trace-transfer {
  background-color: white;
}
.trace-transfer .transfer {
  padding: 0 100px;
}
.trace-transfer .code {
  margin-top: 30px;
  background-color: #e8eeff;
  padding: 20px;
  border-radius: 4px;
}
.steps {
  display: flex;
}
.steps-line {
  margin-top: 30px;
  width: 2px;
  background-color: #aec3ff;
}
.steps-container {
  flex: 1;
}
.steps-item:not(:first-child) {
  margin-top: 20px;
}
.steps-item .steps-title {
  margin-top: 20px;
  color: #777777;
  margin-left: -9px;
  display: flex;
  align-items: center;
}
.steps-item .steps-title .steps-icon {
  display: inline-block;
  width: 8px;
  height: 8px;
  border: 4px solid #aec3ff;
  border-radius: 50%;
  background-color: #002b9e;
  box-sizing: content-box;
}
.steps-item .steps-title .time {
  font-size: 16px;
  margin-left: 20px;
}
.steps-item .steps-title .new {
  margin-left: 20px;
  display: inline-block;
  color: white;
  padding: 0 6px;
  border-radius: 4px;
  background: linear-gradient(90deg, #f85236 0%, #fb7141 100%);
}
.steps-item .steps-content {
  margin-top: 10px;
  margin-left: 30px;
  padding: 10px 20px;
  border-radius: 6px;
  background-color: #f8f8f8;
}
.steps-item .steps-content .item {
  display: flex;
  align-items: center;
  line-height: 1.5;
  color: #333333;
}
.steps-item .steps-content .item .icon {
  width: 15px;
  height: 15px;
  font-size: 15px;
  margin-right: 16px;
  background-size: 100%;
  color: #0035c7;
}
.steps-item .steps-content .item .icon-address {
  background-image: url('../images/trace/icon_address.png');
}
.steps-item .steps-content .item .icon-recorder {
  background-image: url('../images/trace/icon_recorder.png');
}
.steps-item .steps-content .item .dot {
  color: #777777;
}
.steps-item .steps-content .item .content {
  flex: 1;
}
.integral .card {
  margin-bottom: 40px;
}
.detail .mask {
  margin-bottom: 40px;
}
.settle .card {
  margin-bottom: 40px;
}
body {
  background-color: #F6F6F6;
  font-size: 14px;
}
#root {
  min-height: 100%;
  position: relative;
}
.container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 125px;
}
.topBar {
  margin: 0 auto;
  padding: 16px;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.topBar .logo {
  height: 28px;
}
.search-input {
  position: relative;
  display: inline-block;
  height: 36px;
  border-radius: 18px;
  width: 500px;
}
.search-input input {
  width: 100%;
  height: 100%;
  font-size: 16px;
  border-radius: 18px;
  padding-left: 60px;
  padding-right: 20px;
  box-sizing: border-box;
  outline: none;
  color: #202020;
}
.search-input input::placeholder {
  color: #AAAAAA;
}
.search-input .search-icon {
  position: absolute;
  width: 19px;
  height: 19px;
  margin: 8px 0;
  padding: 0 16px;
  box-sizing: content-box;
  border-right: 1px solid #dddddd;
  cursor: pointer;
}
.search-input .search-icon img {
  width: 19px;
  height: 19px;
}
.footer {
  width: 100%;
  height: 125px;
  text-align: center;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  line-height: 125px;
  background: url("../images/home/header-background.jpg") no-repeat center center;
  background-size: 100%;
  margin-top: -125px;
}
.footer a {
  color: white;
}
.ant-table-body::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.ant-table-body::-webkit-scrollbar-thumb {
  background: #002b9e;
  box-shadow: 0 0 1px 0 #1445ca;
  border-radius: 5px;
}
.ant-table-header {
  background-color: #fff;
}
.ant-table thead > tr > th {
  background-color: #fff;
}
.table-container {
  margin-top: 20px;
}
.table-container .table-column {
  font-size: 14px;
  font-weight: 400;
  color: #202020;
  background-color: #fff;
  padding: 10px;
}
.table-container .column-title {
  font-size: 16px;
  font-weight: 600;
  color: #202020;
}
.table-container .word-break {
  word-break: break-all;
}
