.water-container {
  position: absolute;
  top: 0;
  left: 50%;
}
.water-container .water {
  width: 50px;
  height: 50px;
  position: relative;
}
.water-container .water .point {
  position: absolute;
  border-radius: 50%;
  animation: border 2s linear infinite;
}
.water-container .water .point2 {
  animation-delay: 0.5s;
}
.water-container .water .point3 {
  animation-delay: 1s;
}
.water-container .water .point4 {
  animation-delay: 1.5s;
}
@keyframes border {
  from {
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    background-color: #7bcbfc;
  }
  to {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(123, 203, 252, 0);
  }
}
